exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-competition-awards-js": () => import("./../../../src/pages/competition/awards.js" /* webpackChunkName: "component---src-pages-competition-awards-js" */),
  "component---src-pages-competition-categories-js": () => import("./../../../src/pages/competition/categories.js" /* webpackChunkName: "component---src-pages-competition-categories-js" */),
  "component---src-pages-competition-index-js": () => import("./../../../src/pages/competition/index.js" /* webpackChunkName: "component---src-pages-competition-index-js" */),
  "component---src-pages-competition-registration-js": () => import("./../../../src/pages/competition/registration.js" /* webpackChunkName: "component---src-pages-competition-registration-js" */),
  "component---src-pages-competition-rules-js": () => import("./../../../src/pages/competition/rules.js" /* webpackChunkName: "component---src-pages-competition-rules-js" */),
  "component---src-pages-competition-shipping-js": () => import("./../../../src/pages/competition/shipping.js" /* webpackChunkName: "component---src-pages-competition-shipping-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

